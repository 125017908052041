import React from 'react';
import { useConfig } from '../ConfigContext';
import powered_by_foodchain from '../img/powered_by_foodchain.png';

const Loader = ({metadata}) => {


  const config = useConfig();


  if(!metadata || !metadata.platform_logo){
      return (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      );
  }


  var full_white_label = metadata.FULL_WHITE_LABEL;

  return (
    <div className="loader-container">
      <div className="loader-platform">
        <img className="platform-logo" src={config.DOMAIN + metadata.platform_logo} alt="platform logo"/>
      </div>
      <div className="spinner"></div>
      {full_white_label===false &&
        <img className="foodchain-logo" src={powered_by_foodchain} alt="foodchain logo"/>
      }
    </div>
  );

};

export default Loader;
