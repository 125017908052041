import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {is_nonempty_array} from '../tools/utils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import arrowUrl from '../img/arrow_left.png'; // Update the path to your image file

/*
import cosmeticsImage from '../img/category_icons/Cosmetics.svg';
import designImage from '../img/category_icons/Design.svg';
import foodImage from '../img/category_icons/Food.svg';
import pharmaImage from '../img/category_icons/Pharma.svg';
import textileImage from '../img/category_icons/Textile.svg';
*/

const ImageHeader = ({ images, altText, back_path, merchant_category, merchant_video, unfixed }) => {


  const { t } = useTranslation();

  var design_color = null;
  var design_image = null;

  //TODO
  if(merchant_category === 'cosmetics'){
    design_color = null;
    design_image = null;
  }

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  var images_present = is_nonempty_array(images);
  var many_images_present = is_nonempty_array(images) && images.length > 1;
  var dotListClassString = many_images_present ? "custom-dot-list-style" : "custom-dot-list-style-invisible";

  var the_position = unfixed ? "relative" : "fixed";

  return (
    <header style={{position:the_position, width:"min(100%,500px)", height:"min(100vw, 500px)"}}>
    { images_present &&
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={true}
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass={dotListClassString}
        itemClass="carousel-item-padding-40-px"
      >
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Product ${index}`} style={{ width: '100%' }} />
          </div>
        ))}

      </Carousel>
      }
      {
      back_path && <Link to={back_path}>
        <div className="image-header-back-button">

          <span className="arrow-back-icon">
            <img alt="indietro" src={arrowUrl} />
          </span>
          <span>{t('indietro')} </span>

        </div>
      </Link>
      }


     {merchant_category && (
       <div className="side_icon" style={{"backgroundColor": design_color}}>
         <img alt="category" src={design_image} className="side_icon_image" style={{"marginTop":"2px"}} />
       </div>
     )}



    </header>
  );
};

export default ImageHeader;
