import React from 'react';
import { Link } from 'react-router-dom';

const ContactCard = ({ type, imageUrl, name, value }) => {

  if(type==='website'){
    return (

                <Link to={value} >
                <div className="horizontal-card">
                  <div className="contact-image-outer">
                    <img src={imageUrl} alt="contact" className="contact-image" />
                  </div>
                  <div className="contact-info">
                    <div className="contact-name">{name}</div>
                    <div className="contact-value">{value}</div>
                  </div>
                </div>
              </Link>

    );
  }
  if(type==='phone'){
    return (

                <Link to={"tel:"+value} >
                <div className="horizontal-card">
                  <div className="contact-image-outer">
                    <img src={imageUrl} alt="contact" className="contact-image" />
                  </div>
                  <div className="contact-info">
                    <div className="contact-name">{name}</div>
                    <div className="contact-value">{value}</div>
                  </div>
                </div>
              </Link>

    );
  }
  if(type==='mail'){
    return (

                <Link to={"mailto:"+value} >
                <div className="horizontal-card">
                  <div className="contact-image-outer">
                    <img src={imageUrl} alt="contact" className="contact-image" />
                  </div>
                  <div className="contact-info">
                    <div className="contact-name">{name}</div>
                    <div className="contact-value">{value}</div>
                  </div>
                </div>
              </Link>

    );
  }

  if(type==='address'){
    return (

                <Link target="_blank" to={"https://maps.google.com/?q="+value} >
                <div className="horizontal-card">
                  <div className="contact-image-outer">
                    <img src={imageUrl} alt="contact" className="contact-image" />
                  </div>
                  <div className="contact-info">
                    <div className="contact-name">{name}</div>
                    <div className="contact-value">{value}</div>
                  </div>
                </div>
              </Link>

    );
  }

  return (

              <div className="horizontal-card">
                <div className="contact-image-outer">
                  <img src={imageUrl} alt="contact" className="contact-image" />
                </div>
                <div className="contact-info">
                  <div className="contact-name">{name}</div>
                  <div className="contact-value">{value}</div>
                </div>
              </div>

  );
};

export default ContactCard;
