import React, { useEffect, useState, useMemo } from 'react';
import { MapContainer, TileLayer, Polyline, Marker } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import polyline from 'polyline';
import { useConfig } from '../ConfigContext';

import customMarkerImg1 from '../img/pin_2_white.png'; // Update the path to your image file
import customMarkerImg2 from '../img/pin_3_white.png'; // Update the path to your image file
import { calculateMapCenter, getMapZoomLevel } from '../tools/utils.js';

import L from 'leaflet';



const MapRoute = ({points, selected_index}) => {
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const config = useConfig();
  /*var apiKey = config.GOOGLE_API_KEY_ROUTES;*/

  var the_domain = config.DOMAIN;
  var center = [45.712776, 7.005974];
  var zoom=6;

  console.log(points);

  center = calculateMapCenter(points);
  zoom = getMapZoomLevel(points);


  const formatted_points = useMemo(() => {
    let fp = [];
    points.forEach(function(p){
      fp.push(p[0]+","+p[1])
    });
    return fp;
  }, [points]); // Only recalculate when 'points' changes




  // UNSELECTED
  const customIcon1 = L.icon({
      iconUrl: customMarkerImg2,   // Path to your custom marker image
      iconSize: [48, 64],         // Size of the icon
      iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
  });



  // SELECTED
  const customIcon2 = L.icon({
      iconUrl: customMarkerImg1,   // Path to your custom marker image
      iconSize: [48, 64],         // Size of the icon
      iconAnchor: [24, 44],       // Point of the icon which will correspond to marker's location
      popupAnchor: [0, -38],      // Point from which the popup should open relative to the iconAnchor
      forceZIndex: 10000
  });




  useEffect(() => {

    if(!points) return;
    const getComplexRoute = async () => {


      const startPoint = formatted_points[0]; // First point is the start
      const endPoint = formatted_points[formatted_points.length - 1]; // Last point is the end
      const waypoints = formatted_points.slice(1, -1).join('|'); // Intermediate waypoints


      try {
        const response = await axios.get(
          the_domain + `mobile/direction/`,
          {
            params: {
              origin: startPoint,
              destination: endPoint,
              waypoints: waypoints,  // Add waypoints to the request
              /*key: apiKey*/
            }
          }
        );

        // Get the encoded polyline from the response
        const encodedPolyline = response.data.routes[0].overview_polyline.points;

        // Decode the polyline into an array of [lat, lng] points
        const decodedCoordinates = polyline.decode(encodedPolyline);
        // Set the decoded coordinates as the route in state
        setRouteCoordinates(decodedCoordinates);
      } catch (error) {
        console.error('Error fetching complex route data:', error);
      }
    };

    getComplexRoute();
  }, [points, formatted_points, the_domain]);

  return (
    <MapContainer center={ center ? center : [45.712776, 7.005974]} zoom={zoom?zoom:5} style={{ height: '300px', width: '100%' }}>
      <TileLayer
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />


      {points && points.length>0 && points.map((position, index) => (
          <Marker
              key={index}
              position={position}
              icon={index===selected_index ? customIcon2 : customIcon1}   // Use the custom icon
              zIndexOffset={index===selected_index ? 4000 : 3000}
          >
              {/*<Popup>
                  [{position[0]}, {position[1]}]
              </Popup>*/}
          </Marker>
      ))}


      {/* Render the polyline if routeCoordinates is available */}
      {routeCoordinates.length > 0 && (
        <Polyline positions={routeCoordinates} color="#48A7AF" />
      )}
    </MapContainer>
  );
};

export default MapRoute;
