import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import ImageHeader from '../components/ImageHeader';
import ProcessDates from '../components/ProcessDates';
import Certifications from '../components/Certifications';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExtraData from '../components/ExtraData';
import Video from '../components/Video';
import Loader from '../components/Loader';

import Documents from '../components/Documents';
import Machinery from '../components/Machinery';
import ProcessBatches from '../components/ProcessBatches';
import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';


import { useConfig } from '../ConfigContext';
import defaultProcessImage from '../img/default_images/foodchain_processing.png';


const ProcessDetailPage = ({qr_code_hash, data}) => {

  const config = useConfig();
  const { index } = useParams();
  const { t } = useTranslation();


  const metadata = useMetadata();


  var the_proc = null;

  var processingImage = [defaultProcessImage];
  var processingName = null;
  var processingDescription = null;
  var processingDateStart = null;
  var processingDateEnd = null;
  var extra_data = null;
  var number_of_processes = 0;
  var next_path = null;
  var previous_path = null;
  var document_list = null;
  var machineries = [];
  var the_certifications = [];

  const processesPath = "/"+qr_code_hash+"/processes";

  var process_list = [];
  var data_type = '';



  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);


  if(data && data.processings && data.processings.length>index){
    process_list = data.processings;
    data_type = 'processing';
  }
  else if(data && data.template && data.template.length>index){
    process_list = data.template;
    data_type = 'template';
  }

  if(process_list.length>0)
  {

    number_of_processes = process_list.length;
    if(index < number_of_processes-1){
      next_path = config.URL_DOMAIN+(qr_code_hash)+"/process/"+(parseInt(index,10)+1);
    }
    if(index > 0){
      previous_path = config.URL_DOMAIN+(qr_code_hash)+"/process/"+(parseInt(index,10)-1);
    }

    the_proc = data_type === 'processing' ? process_list[index].foodchain_processing : process_list[index].foodchain_template;
    processingImage = (the_proc.images && the_proc.images.length>0) ? [config.DOMAIN+"/"+the_proc.images[0].img_low_cropped] : [defaultProcessImage];
    processingName = the_proc.name;
    processingDescription = the_proc.description;
    processingDateStart = the_proc.date_start;
    processingDateEnd = the_proc.date_end;
    extra_data = the_proc.extra;
    document_list = the_proc.files;
  }

  if(data && data.certification){
    the_certifications = data.certification.map((c) => c.foodchain_certification).filter((c)=>c.related_object_type==='processing' && c.related_object_id === the_proc.item_id)
  }

  if(the_proc && data && data.machinery && data.machinery.length>0){
    machineries = data.machinery.map((m)=>m.foodchain_machinery).filter(
      (m)=>m.son === the_proc.item_id
    );
  }


  var back_path = config.URL_DOMAIN+(qr_code_hash)+"/processes";


  if(!data){
    return(
      <PageContainer>
        <div className="page-body  page-body-loader">
          <Loader metadata={metadata} />
        </div>
      </PageContainer>
    )
  }



  return (
    <PageContainer>
       <ImageHeader back_path={back_path} images={processingImage} altText="process" back_button_link = {processesPath} unfixed={true}/>

       <div className="page-body page-body-nonavbar" style={{marginTop:"-60px"}}>
        <div className="detail-type-label">
          {t('processo')}
        </div>
           {/* <ProcessIndexBox index={index} /> */}

           <h1 className="gtrans" style={{marginTop:"7px"}}>{processingName}</h1>


           <p className="high-line-height gtrans">{processingDescription}</p>

           <ProcessDates date_start={processingDateStart} date_end={processingDateEnd}/>


           { the_certifications && the_certifications.length>0 && <Certifications data={the_certifications} /> }

           { data &&
              <ProcessBatches data={the_proc} index={index}/>
           }


        <div>
            <Documents documents={document_list}/>
        </div>


        <div>
          { machineries && machineries.length>0 &&
            (
              <h2>{t('macchinari')}</h2>
            )
          }
          { machineries && machineries.length>0 && machineries.map((m, index) => (
            <Machinery machinery={m} key={index} />
          ))
          }
        </div>


        <ExtraData data={extra_data} />

        <Video data={extra_data} />

        { (previous_path || next_path) &&
          <div className="process-navigation-buttons">
            <div>
              {previous_path ?
                <Link to={previous_path}>
                  <div className="not-so-big-button">
                    {t('precedente')}
                  </div>
                </Link>
                :
                <div className="not-so-big-button-disabled">
                    {t('precedente')}
                </div>
            }
            </div>
            <div className={next_path===null ? 'invisible' : '' }>
              {next_path ?
                <Link to={next_path}>
                  <div className="not-so-big-button">
                    {t('successivo')}
                  </div>
                </Link>
                :
                <div className="not-so-big-button-disabled">
                    {t('successivo')}
                </div>
            }
            </div>


          </div>
        }

      </div>



    </PageContainer>
  );
};

export default ProcessDetailPage;
