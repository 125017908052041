import React from 'react';
import {execute_RTL, toggle_global_RTC} from '../tools/utils';
import FooterNavigatorItem from '../components/FooterNavigatorItem';

const FooterNavigator = ({data, qr_code_hash, selected_page, is_there_supplychain}) => {


  return (
    <footer>
      <nav className="footer-navigator">

        <FooterNavigatorItem qr_code_hash={qr_code_hash} page={"dettaglio"}  selected={selected_page===1} />
        <FooterNavigatorItem qr_code_hash={qr_code_hash} page={"processi"}   selected={selected_page===3} />
        {is_there_supplychain && <FooterNavigatorItem qr_code_hash={qr_code_hash} page={"filiera"}    selected={selected_page===4} />}
        <FooterNavigatorItem qr_code_hash={qr_code_hash} page={"azienda"}    selected={selected_page===2} />

      </nav>

      <div style={{display:'none'}} className="footer-bottom" onClick={()=>{toggle_global_RTC(); execute_RTL()}}>
       RTL
      </div>

    </footer>
  );
};

export default FooterNavigator;


