import React, { useState, useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import FirstPage from './pages/FirstPage';
import MerchantPage from './pages/MerchantPage';
import ProcessesPage from './pages/ProcessesPage';
import SupplyChainPage from './pages/SupplyChainPage';
import ProcessDetailPage from './pages/ProcessDetailPage';
import CertificationDetailPage from './pages/CertificationDetailPage';
import PlaceDetailPage from './pages/PlaceDetailPage';
import Page404 from './pages/Page404';
import ProductDetailPage from './pages/ProductDetailPage';
import { fetchData } from './tools/datafetch';
import { ConfigProvider } from './ConfigContext';
import { useLocation } from 'react-router-dom';
import {execute_RTL, reset_background_size, compute_supplychain} from './tools/utils';
import { MetadataProvider } from './contexts/MetadataProvider';
import ReactGA from 'react-ga4';

const App = () => {


    const config = useMemo(() => {
      return {
        CONTRACT: window._env_.REACT_APP_CONTRACT,
        RPC: window._env_.REACT_APP_RPC,
        DOMAIN: window._env_.REACT_APP_DOMAIN,
        URL_DOMAIN: window._env_.REACT_APP_URL_DOMAIN,
        IPFS_DOMAIN: window._env_.REACT_APP_IPFS_DOMAIN,
        ROOT_DOMAIN: window._env_.REACT_APP_ROOT_DOMAIN,
        GOOGLE_API_KEY_TRANSLATIONS: window._env_.REACT_APP_GOOGLE_API_KEY_TRANSLATIONS,
        GOOGLE_API_KEY_ROUTES: window._env_.REACT_APP_GOOGLE_API_KEY_ROUTES,
        DEBUG: window._env_.REACT_APP_DEBUG === 'true', // Convert to boolean if needed
        POLICY_POPUP_TEXT: window._env_.REACT_APP_POLICY_POPUP_TEXT, // Convert to boolean if needed
        METADATA_BASEURL: window._env_.REACT_APP_METADATA_BASEURL,
        COMPOSITION_LABEL_TYPE: window._env_.REACT_APP_COMPOSITION_LABEL_TYPE,
        GOOGLE_ANALYTICS_ACTIVE: window._env_.REACT_APP_GOOGLE_ANALYTICS_ACTIVE,
        GOOGLE_ANALYTICS_MEASUREMENT_ID: window._env_.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
      }
    }, []);


    const fullUrl = window.location.href;
    const withoutDomain = fullUrl.replace(config.URL_DOMAIN, "");
    const withoutReferer = withoutDomain.split("?")[0];
    const qr_code_hash = withoutReferer.split("/")[0];
    //const pieces_for_referer = withoutDomain.split("?referer=");
    //const referer = pieces_for_referer.length>1 ? pieces_for_referer[1] : null;


    console.log(config);



    if(config.GOOGLE_ANALYTICS_ACTIVE==='true'){
       ReactGA.initialize(config.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    }

    // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [show404Page, setShow404Page] = useState(null);
    const [error, setError] = useState(null);
    const [productData, setProductData] = useState(null); // State for the image URL
    const [merchantData, setMerchantData] = useState(null); // State for the image URL
    const [otherData, setOtherData] = useState(null); // State for the image URL
    const [multibatchData, setMultibatchData] = useState(null); // State for the image URL
    //const [downwardData, setDownwardData] = useState(null); // State for the image URL

    const productCallback = (data) => {
      if(data && data.foodchain_product){
        setProductData(data.foodchain_product);
      }
    };

    const merchantCallback = (data) => {
      if(data && data.foodchain_merchant){
        setMerchantData(data.foodchain_merchant);
      }
    };

    const otherCallback = (data) => {
      if(data){
        setOtherData(data);

        const globals = document.getElementById('globals');
        var the_supplychain = compute_supplychain(data);

        if(the_supplychain && the_supplychain.length>1){
          globals.setAttribute('is_there_supplychain', "true");
        }
        else{
          globals.setAttribute('is_there_supplychain', "false");
        }
      }
    };

    const multibatchCallback = (batches_data, product) => {
      if(batches_data){
        setMultibatchData(batches_data);
        setProductData(product);
      }
    }


    const downwardCallback = (data) => {
      if(data){
        //setDownwardData(data);
      }
    }

    const location = useLocation();


    useEffect(() => {

        const loadData = async () => {
            try {

                //const globals = document.getElementById('globals');
                //globals.setAttribute('referer', referer);

                //const result = await fetchData('0xf00d00000000000100000000007887a9ab4d7606343014da0d31d11616e86fbe', config, productCallback, merchantCallback, otherCallback);
                //const result = await fetchData('0xf00d0000000000010000000000abb3a49e56a5aca0339e250d2a24ea16e86fbe', config, productCallback, merchantCallback, otherCallback);
                const result = await fetchData("0x"+qr_code_hash, config, productCallback, merchantCallback, otherCallback, downwardCallback, multibatchCallback);
                if(result===null){
                  setShow404Page(true);
                }
                setData(result);

            } catch (err) {
                setError(err);
            }
        };

        loadData();
    }, [config, qr_code_hash]); // Empty dependency array means this effect runs once when the component mounts



    useEffect(() => {
      window.scrollTo(0, 0);
      execute_RTL();
    }, [location]); // Empty dependency array means this effect runs once when the component mounts


    var google_analytics_active = config.GOOGLE_ANALYTICS_ACTIVE;

    useEffect(() => {
      if(google_analytics_active==='true'){
          ReactGA.send({ hitType: "pageview", page: window.location.pathname });
      }

      reset_background_size();
      window.addEventListener('resize', () => {
        reset_background_size();
      })
    }, [google_analytics_active]); // Empty dependency array means this effect runs once when the component mounts



    if (error){
      console.error(error);
      return (
        <Page404 />
      )
      //return <div>Error: {error.message}</div>;
    }

  const homePath                  = qr_code_hash+"/";
  const merchantPath              = qr_code_hash+"/merchant";
  const processesPath             = qr_code_hash+"/processes";
  const supplyChainPath           = qr_code_hash+"/supplychain";
  const processDetailPath         = qr_code_hash+"/process/:index";
  const certificationDetailPath   = qr_code_hash+"/certification/:sha/:from";
  const warehouseDetailPath       = qr_code_hash+"/warehouse/:place_id";
  const fieldDetailPath           = qr_code_hash+"/field/:place_id";
  const productDetailPath         = qr_code_hash+"/product/:item_id";


  if(show404Page===true){
    return (
      <Page404 />
    )
  }

  return (
    <ConfigProvider config={config}>
    <MetadataProvider hashCode={qr_code_hash}>
        <Routes>
          <Route path={homePath}                    element={<FirstPage               qr_code_hash={qr_code_hash}  multibatchData={multibatchData}  productData={productData} merchantData={merchantData} otherData={otherData} />} />
          <Route path={merchantPath}                element={<MerchantPage            qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={processesPath}               element={<ProcessesPage           qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={supplyChainPath}             element={<SupplyChainPage         qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={processDetailPath}           element={<ProcessDetailPage       qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={certificationDetailPath}     element={<CertificationDetailPage qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={warehouseDetailPath}         element={<PlaceDetailPage         qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={fieldDetailPath}             element={<PlaceDetailPage         qr_code_hash={qr_code_hash}  data={otherData} />} />
          <Route path={productDetailPath}           element={<ProductDetailPage       qr_code_hash={qr_code_hash}  data={otherData} />} />
        </Routes>
    </MetadataProvider>
    </ConfigProvider>
  );
};
export default App;
