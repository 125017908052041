import React from 'react';
import ExtraDataItem from './ExtraDataItem';
import { useTranslation } from 'react-i18next';

const ExtraData = ({ data }) => {

  const { t } = useTranslation();

  if(data===null || typeof data === 'undefined' || Object.keys(data).filter((key)=>key!=='video').filter((key)=>key!=='url_ecommerce').length === 0){
     return <div></div>;
  }

  return (
    <div>
        <h2>{t('extra_data')}</h2>
        <div className="extra-data">
          {Object.keys(data)
            .filter((key)=>key!=='video')
            .filter((key)=>key!=='url_ecommerce')
            .map((key) => (
            <ExtraDataItem key={key} label={key} value={data[key]} />
          ))}
        </div>
    </div>
  );
};

export default ExtraData;
