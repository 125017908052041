import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {format_date} from "../tools/utils.js";

const ProcessCard = ({ qr_code_hash, imageUrl, index, date, title, description }) => {
  const { t } = useTranslation();

  const dettaglioProcessPath = "/"+qr_code_hash+"/process/"+index

  return (
    <Link to={dettaglioProcessPath} >
      <div className="process-card">
        <div className="process-card-image-outer">
          <img src={imageUrl} alt="Process" className="process-card-image" />
        </div>


        {/*<div className="process-card-labels">
          <div className="process-card-index">
            <div className="process-card-index-icon">
              <img src={processCardIcon} alt="Processo"/>
            </div>
            <div className="process-card-index-text">
              {t('processo')} {parseInt(index,10)+1}
            </div>
          </div>
          <div className="process-card-date">{format_date(date)}</div>
        </div>*/}



        <div className="process-card-content">
          <div className="detail-type-label" style={{marginBottom:"3px"}}>
            {t('processo')} <span className="process-date-right">{format_date(date)}</span>
          </div>
          <h2 className="process-card-title gtrans">{title}</h2>
          <p className="process-card-description gtrans">{description}</p>
        </div>
      </div>
    </Link>
  );
};


export default ProcessCard;
