import React from 'react';
import {smart_newlines} from '../tools/utils';
import imago from '../img/lotto.png';

const BatchBox = ({ claim, name }) => {

  return (
    <div
      className="batch-box"
    >
      <div className="batch-box-icon">
        <img src={imago} alt={claim} className="batch-box-image"/>
      </div>
      <div className="batch-box-content">
        <div className="batch-box-type">
           LOTTO
        </div>
        <div dangerouslySetInnerHTML={{ __html: smart_newlines(name,35) }} className="batch-box-title"></div>
        <div className="batch-box-value">

        </div>
      </div>


    </div>
  );
};

export default BatchBox;
