import React from "react";
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import image404 from "../img/404.png";
const Page404 = () => {

  const { t } = useTranslation();

  /*const handleOpenCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Camera is open! You can add additional handling if needed.
      console.log("Camera opened successfully", stream);

      // Stop the stream after opening (optional, if you only want to "open" it briefly)
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };*/

  return (

      <PageContainer>
        <div className="page-body page-body-noheader" style={{backgroundColor:"white", margin:"00px", padding:"0px", marginTop: "20px"}}>
          <img style={{width:"100%", marginTop:"20%"}} src={image404} alt="404" />

          <div style={{marginLeft:"20px", marginRight:"20px", marginTop:'50px', textAlign:'center', fontSize:'12pt'}}>
           {t('page_not_found_title')}
          </div>
          <div style={{marginLeft:"20px", marginRight:"20px", marginTop:'20px', textAlign:'center', fontSize:'12pt'}}>
           {t('page_not_found')} <br/>
           {t('page_not_found_2')}
          </div>
        </div>
        {/*<div>
        <div className="big-button-container">
          <button className="big-button" onClick={handleOpenCamera}>
            {t("apri_fotocamera")}
          </button>
        </div>
        </div>*/}
      </PageContainer>



  );
};

export default Page404;
