import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import ImageHeader from '../components/ImageHeader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import Map from '../components/Map';

import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';
import Documents from '../components/Documents';
import ExtraData from '../components/ExtraData';


import { useConfig } from '../ConfigContext';
import defaulFieldImage from '../img/default_images/foodchain_field.png';
import defaultWarehouseImage from '../img/default_images/foodchain_warehouses.png';


const PlaceDetailPage = ({qr_code_hash, data}) => {

  const config = useConfig();
  const { place_id } = useParams();
  const { t } = useTranslation();


  const metadata = useMetadata();
  var the_place = null;


  var placeName;
  var placeDescription;
  var placeImage;
  var defaultPlaceImage;
  var placeLocation;
  var mapPositions = [];

  var document_list = null;
  var extra_data = null;


  var the_back_path = config.URL_DOMAIN+qr_code_hash+"/merchant";

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);

  var places = [];

  if(data && data.warehouse){
    places = data.warehouse.map((w)=>w.foodchain_warehouse).filter((w)=>w.item_id === place_id);
    if(places.length>0){
       the_place = places[0];
       defaultPlaceImage = defaultWarehouseImage;
       document_list = the_place.files;
       extra_data = the_place.extra;

    }
  }
  if(data && data.fields){
    places = data.fields.map((w)=>w.foodchain_field).filter((w)=>w.item_id === place_id);
    if(places.length>0){
       the_place = places[0];
       defaultPlaceImage = defaulFieldImage;
       document_list = the_place.files;
       extra_data = the_place.extra;

    }
  }



  if(the_place){
     placeImage = the_place.images && the_place.images.length && the_place.images[0].image_cropped ? ( config.DOMAIN + the_place.images[0].image_cropped) : defaultPlaceImage;
     placeName = the_place.name;
     placeDescription = the_place.description;
     placeLocation = the_place.location ? the_place.location.formatted : '';
     mapPositions = the_place.location && the_place.location.latitude? [[the_place.location.latitude,the_place.location.longitude,'']] : [];
  }
  else{
    return(
      <PageContainer>
        <div className="page-body page-body-loader">
          <Loader metadata={metadata} />
        </div>
      </PageContainer>
    )
  }




  return (
    <PageContainer>
       <ImageHeader images={[placeImage]} back_path={the_back_path} altText="backbutton" unfixed={true}/>

       <div className="page-body  page-body-nonavbar" style={{marginTop:"-60px"}}>
        <div className="detail-type-label">
          {t('luogo')}
        </div>
        <h1 className="gtrans" style={{marginTop:"7px"}}>{placeName}</h1>

        <p className="gtrans">{placeLocation}</p>

        <p className="gtrans">{placeDescription}</p>


        { mapPositions && mapPositions.length>0 &&
          <div>
              <h2>{t('posizione')}</h2>
              <div className="map-outer">
                  <Map mapPositions={mapPositions} zoomControl={true}/>
              </div>
          </div>
        }


          <div>
              <Documents documents={document_list}/>
          </div>


       <ExtraData data={extra_data} />

       </div>




    </PageContainer>
  );
};

export default PlaceDetailPage;
