import React, { useEffect } from 'react';
import PageContainer from '../components/PageContainer';
import ImageHeader from '../components/ImageHeader';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import Map from '../components/Map';

import {google_translate_all} from '../tools/utils';
import { useMetadata } from '../contexts/MetadataProvider';
import Documents from '../components/Documents';
import ExtraData from '../components/ExtraData';



import { useConfig } from '../ConfigContext';
import defaulProductImage from '../img/default_images/foodchain_products.png';


const PlaceDetailPage = ({qr_code_hash, data}) => {

  const config = useConfig();
  const { item_id } = useParams();
  const { t } = useTranslation();


  const metadata = useMetadata();
  var the_product = null;


  var productName;
  var productDescription;
  var productImage;
  var productLocation;
  var mapPositions;

  var the_back_path = config.URL_DOMAIN+qr_code_hash+"/";


  var document_list = null;
  var extra_data = null;

  useEffect(() => {
    google_translate_all();
    return () => {
    };
  }, []);

  if(data && data.products){
    var products = data.products.map((w)=>w.foodchain_product).filter((w)=>w.item_id === item_id);
    if(products.length>0){
       the_product = products[0];
    }
  }


  if(the_product){
     productImage = the_product.images && the_product.images.length && the_product.images[0].image_cropped ? ( config.DOMAIN + the_product.images[0].image_cropped) : defaulProductImage;
     productName = the_product.name;
     productDescription = the_product.description;
     productLocation = the_product.location ? the_product.location.formatted : '';
     mapPositions = the_product.computed_coords ? [[the_product.computed_coords[0],the_product.computed_coords[1],'']] : [];
     document_list = the_product.files;
     extra_data = the_product.extra;
  }
  else{
    return(
      <PageContainer>
        <div className="page-body page-body-loader">
          <Loader metadata={metadata} />
        </div>
      </PageContainer>    )
  }



  return (
    <PageContainer>
       <ImageHeader images={[productImage]} back_path={the_back_path} altText="backbutton" unfixed={true}/>
       <div className="page-body page-body-nonavbar" style={{marginTop:"-60px"}}>
        <div className="detail-type-label">
          {t('componente')}
        </div>
        <h1 className="gtrans" style={{marginTop:"7px"}}>{productName}</h1>
        <p className="gtrans">{productDescription}</p>
        <p className="gtrans">{productLocation}</p>

        { mapPositions && mapPositions.length>0 &&
          <div>
              <h2>{t('origine')}</h2>
              <div className="map-outer">
                  <Map mapPositions={mapPositions} zoomControl={true}/>
              </div>
          </div>
        }




          <div>
              <Documents documents={document_list}/>
          </div>


       <ExtraData data={extra_data} />


       </div>



    </PageContainer>
  );
};

export default PlaceDetailPage;
